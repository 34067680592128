import React from "react";

function ProdilePFP(props: any) {
  const { image } = props;
  return (
    <img
    src={image}
    className="rounded-lg profile-duck md:h-3/6"
    alt="Duck Profile" />
  );
}

export default ProdilePFP;