import React from "react";
import logoIcon from "../assets/Logo.png"

function Logo() {
  return (
    <img src={logoIcon} alt="Nonco Ducks Logo" className="h-full"/>
  );
}

export default Logo;
