import React, {useState,useEffect} from "react";
import BannerGold from "../assets/Badges-Banner-Oro.png"
import BannerSilver from "../assets/Badges-Banner-Plata.png"
import DiamondGold from "../assets/Badges-Diamante-Oro.png"
import DiamondSilver from "../assets/Badges-Diamante-Plata.png"
import PFPGold from "../assets/Badges-PFP-Oro.png"
import PFPSilver from "../assets/Badges-PFP-Plata.png"

const badgesInfo = {
  BANNER: {
    completed: BannerGold,
    incomplete: BannerSilver,
    alt: 'Banner Badge'
  },
  PFP: {
    completed: PFPGold,
    incomplete: PFPSilver,
    alt: 'Profile Picture Badge'
  },
  DIAMOND: {
    completed: DiamondGold,
    incomplete: DiamondSilver,
    alt: 'Diammond Badge'
  }
}

function Badges(props: any) {
  const { badges, collection } = props;
  const [pfpBadge, setPfpBadge] = useState<any>({
    image: badgesInfo.PFP.incomplete,
    alt: badgesInfo.PFP.alt
  });
  const [bannerBadge, setBannerBadge] = useState<any>({
    image: badgesInfo.BANNER.incomplete,
    alt: badgesInfo.BANNER.alt
  });
  const [diamondBadge, setDiamondBadge] = useState<any>({
    image: badgesInfo.DIAMOND.incomplete,
    alt: badgesInfo.DIAMOND.alt
  });


  useEffect(() => {
    badges.forEach( (badge:any) => {
      const badgeInfo = badgesInfo[badge.name as keyof typeof badgesInfo]
      const image = badge.status ? badgeInfo.completed : badgeInfo.incomplete;
      const alt = badgesInfo[badge.name as keyof typeof badgesInfo].alt;
      switch (badge.name) {
        case 'BANNER':
          setBannerBadge({
            image,
            alt
          })
          break;
        case 'PFP':
          setPfpBadge({
            image,
            alt
          })
          break;
        case 'DIAMOND':
          if (badge.collection === collection) {
            setDiamondBadge({
                image,
                alt
            })
          }
          break;
      }
    })
  }, [ badges, collection ]);

  return (
    <div className="flex flex-row items-center justify-around w-full h-1/4 md:h-2/6">
      <img src={pfpBadge.image} alt={pfpBadge.alt} className="h-4/6" key={pfpBadge.alt}/>
      <img src={bannerBadge.image} alt={bannerBadge.alt} className="h-4/6" key={bannerBadge.alt}/>
      <img src={diamondBadge.image} alt={diamondBadge.alt} className="h-4/6" key={diamondBadge.alt}/>
    </div>
  )
}


export default Badges;