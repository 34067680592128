import React, {useState, useEffect} from "react";
import Background from "./Background";
import Profile from "./Profile";
import Tabs from "./Tabs";
import ConnectWallet from "./ConnectWallet";
import { useStateContext } from "../utils/web3";
import { getProfile, fetchDucks } from "../utils/api";
import { COLLECTIONS } from "../utils/constants";
import ReactTooltip from 'react-tooltip';

function Royalties() {
  const { isConnected, account, balance, accessToken } = useStateContext();
  const [selectedCollection, setSelectedCollection] = useState<string>(COLLECTIONS.OG);
  const [user, setUser] = useState<any>(null);
  const [totalDucks, setTotalDucks] = useState<number>(0);
  async function fetchProfile(_accessToken: string) {
    const response = await getProfile(_accessToken);
    setUser(response);
  }

  async function getDucks(collection:string) {
    let res = [];
    if ( collection === COLLECTIONS.SECOND) {
      res = await fetchDucks(account,collection);
    } else {
      res = await balance(collection)
    }
    setTotalDucks(res);
  }

  useEffect(() => {
    if (isConnected) {
      fetchProfile(accessToken);
    }
  }, [isConnected, accessToken]);

  useEffect(() => {
    if (isConnected && account) {
      getDucks(selectedCollection);
    }
  }, [isConnected, account, selectedCollection]); //eslint-disable-line

  return (
    <>
      <ReactTooltip />
      <div className="flex w-full h-4/5 z-10">
          {
            isConnected ? 
            (
              <div className="flex w-9/12 oh-90 m-auto relative">
                <div className="absolute flex-col tabs">
                  <Tabs action={setSelectedCollection}></Tabs>
                </div>
                <Profile user={user} collection={selectedCollection} totalDucks={totalDucks}></Profile>
              </div>
            )
            :
            (
              <ConnectWallet></ConnectWallet> 
            )
          }
      </div>
      <Background selected={selectedCollection}></Background>
    </>
  )
}

export default Royalties;