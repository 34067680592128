import OGButton from "../assets/Boton-OG.png";
import ScorchButton from "../assets/Boton-Hell.png";
import SecondGenButton from "../assets/Boton-2nd.png";
import SECOND from "../assets/Patito-2nd.png";
import OG from "../assets/Patito-OG.png";
import HELL from "../assets/Patito-Hell.png";

const OG_CONTRACT_ADDRESS = "0x0F4B28D46CAB209bC5fa987A92A26a5680538e45";
const SCORCHING_CONTRACT_ADDRESS = "0x7B7dAd77E4090160F9cB6BA57A5a774c12D4c28a";
const SECOND_CONTRACT_ADDRESS = "0x488fb59d73669d5a1cdf0960214563756594c528";
const ROYALTIES_CONTRACT_ADDRESS = "0x94aFC2D7350A13726DEcA5Ba6476701EbD93Ef68";

const COLLECTIONS = {
  OG: 'nonconformistducks',
  HELL: 'nonconformisthellducks',
  SECOND: 'noncoducks2gen'
}

const royaltiesTabs = [
  {
    image: OGButton,
    value: COLLECTIONS.OG,
    alt: 'OG Royalties'
  },
  {
    image: ScorchButton,
    value: COLLECTIONS.HELL,
    alt: 'Scorched Royalties'
  },
  {
    image: SecondGenButton,
    value: COLLECTIONS.SECOND,
    alt: 'Second Gen Royalties'
  },
];

const rubberDucks = {
  'noncoducks2gen': SECOND,
  'nonconformistducks': OG,
  'nonconformisthellducks': HELL
};

const badgesInfo = [
  {
    name: 'BANNER',
    status: false
  },
  {
    name: 'PFP',
    status: false
  },
  {
    name: 'DIAMOND',
    status: false
  },
];

export {
  royaltiesTabs,
  rubberDucks,
  badgesInfo,
  COLLECTIONS,
  OG_CONTRACT_ADDRESS,
  SCORCHING_CONTRACT_ADDRESS,
  SECOND_CONTRACT_ADDRESS,
  ROYALTIES_CONTRACT_ADDRESS
};