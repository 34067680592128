import React from 'react';
import Header from './components/Header';
import Royalties from './components/Royalties';
import './App.css';
import {StateProvider} from './utils/web3'
import Div100vh from 'react-div-100vh'

function App() {
  return (
    <StateProvider>
      <Div100vh className="w-screen h-screen">
        <Header></Header>
        <Royalties></Royalties>
      </Div100vh>
    </StateProvider>
  );
}

export default App;
