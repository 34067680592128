import React from "react";
import { isMobile } from "react-device-detect";
import SecondGenBackground from "../assets/Fondo-2nd.jpg"
import OGBackground from "../assets/Fondo-OG.jpg"
import ScorchedBackground from "../assets/Fondo-Hell.jpg"
import SecondGenBackgroundMobile from "../assets/2ndMobile.jpg"
import OGBackgroundMobile from "../assets/og-mobile.jpg"
import ScorchedBackgroundMobile from "../assets/Scorching-Fondo-Mobiles.jpg"
import { COLLECTIONS } from "../utils/constants";

function Background(props: any) {
  const { selected } = props;
  return (
    <>
      <img src={isMobile? SecondGenBackgroundMobile : SecondGenBackground} className={`fixed w-full h-screen top-0 left-0 background ${selected === COLLECTIONS.SECOND ? 'primary' : 'secondary'}-background`} alt="Background"/>
      <img src={isMobile? OGBackgroundMobile : OGBackground} className={`fixed w-full h-screen top-0 left-0 background ${selected === COLLECTIONS.OG ? 'primary' : 'secondary'}-background`} alt="Background"/>
      <img src={isMobile? ScorchedBackgroundMobile : ScorchedBackground} className={`fixed w-full h-screen top-0 left-0 background ${selected === COLLECTIONS.HELL ? 'primary' : 'secondary'}-background`} alt="Background"/>
    </>
  )
}

export default Background;