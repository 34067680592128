import React from "react";
import { rubberDucks } from "../utils/constants";

function TotalDucks(props: any) {
  const { collection, amount } = props;
  return (
    <div className="flex flex-row items-end h-full w-1/4">
      <img
        src={rubberDucks[collection as keyof typeof rubberDucks]}
        className="w-3/5"
        alt="Rubber Duck" />
      <div className="uppercase font-bold text-white text-5xl ">
        {amount}
      </div>
    </div>
  )
  
}

export default TotalDucks;