import React from "react";

function ConnectSocial(props: any) {
  const { text, action, imageSrc, alt } = props;
  return (
    <button onClick={action} className="flex items-center og-bg-black rounded-lg m-auto h-2/5 md:h-3/5 px-2 py-1 social">
      <img src={imageSrc} alt={alt} className="h-3/5 md:h-4/5 mr-2"/>
      <div className="uppercase txt-white text-4xl md:text-2xl">
        {`Connect to ${text}`}
      </div>
    </button>
  );
}

export default ConnectSocial;