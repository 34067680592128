import axios from "axios";

export const BASE_URL = process.env.API_URL ? process.env.API_URL : "https://api.nonconformistducks.com/";

// @ts-ignore
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})


async function getSignature() {
  const res = await api.get("account/token/")
  return res.data.token;
}

async function loginWallet(message:string, address: string) {
  const res = await api.post( "account/token/", {
    "signature": message,
    "address": address
  });
  return res.data.access;
}

async function getProfile(token: string) {
  const res = await api.get(
      "account/profile/",
      {headers: {
        'Authorization': "Bearer " + token,
      }});
  return res.data;
}

async function getProofs(token: string) {
  const res = await api.get(
      "/reward/proofs",
      {headers: {
          'Authorization': "Bearer " + token,
        }}
  );
  return res.data;
}

async function fetchDucks(address:string, selectedCollection:string) {
  const res1:any = await axios.get(
    `https://api.opensea.io/api/v1/assets?owner=${address}&offset=0&limit=50&collection=${selectedCollection}`,
    {headers: {'X-API-KEY': '07e8f6365ba14b46b3d1c5842d9d10a0'}}
  );
  const result1 = await res1.data.assets.filter(( egg:any ) => (parseInt(egg.token_id) < 94 && parseInt(egg.token_id) > 3) );
  let total = result1.length;
  const res2:any = await axios.get(
    `https://api.opensea.io/api/v1/assets?owner=${address}&offset=49&limit=50&collection=${selectedCollection}`,
    {headers: {'X-API-KEY': '07e8f6365ba14b46b3d1c5842d9d10a0'}}
  );
  const result2 = res2.data.assets.filter(( egg:any ) => (parseInt(egg.token_id) < 94 && parseInt(egg.token_id) > 3) );
  total = total + result2.length;
  return total ? total : 0;
}

async function fetchDuck(address:string, selectedCollection:string) {
  const res1:any = await axios.get(
    `https://api.opensea.io/api/v1/assets?owner=${address}&offset=0&limit=50&collection=${selectedCollection}`,
    {headers: {'X-API-KEY': '07e8f6365ba14b46b3d1c5842d9d10a0'}}
  );
  if (selectedCollection === "noncoducks2gen") {
    return res1.data.assets[0] && res1.data.assets.filter(( egg:any ) => (parseInt(egg.token_id) < 94 && parseInt(egg.token_id) > 3) )[0].image_url;
  } else {
    return res1.data.assets[0] && res1.data.assets[0].image_url;
  }
}

export {
  getProfile,
  getProofs,
  fetchDuck,
  fetchDucks,
  getSignature,
  loginWallet
};