import React from "react";
import { royaltiesTabs } from "../utils/constants";

function Tabs(props: any) {
  const { action } = props;
  return (
    <>
      {royaltiesTabs.map( tab => {
        return (
          <button onClick={() => action(tab.value)} key={tab.alt}>
            <img src={tab.image} alt={tab.alt}/>
          </button>
        )
      })}
    </>
  )
}

export default Tabs;