import React from "react";
import Logo from "./Logo";
import { useStateContext } from "../utils/web3";

function Header() {
  const { isConnected, disconnectWallet } = useStateContext();
  return (
    <div className="flex flex-col md:flex-row items-center w-full justify-between p-5 bg-transparent z-20 h-1/5">
      <Logo></Logo>
      {
        isConnected && 
        (
          <button onClick={disconnectWallet} className="flex og-bg-black rounded-lg h-fit px-2 py-1">
            <div className="m-auto leading-normal uppercase txt-primary text-6xl md:text-3xl"> 
              {"Disconnect Wallet"}
            </div>
        </button>
        )
      }

    </div>
  );
  
}

export default Header;