import React, {useState, useEffect} from "react";
import Badges from "./Badges";
import TotalDucks from "./TotalDucks";
import Banner from "../assets/Banner.png"
import TwitterIcon from "../assets/BotonTwitter-Solo.png";
import ConnectSocial from "./ConnectSocial";
import ProdilePFP from "./ProdilePFP";
import { use100vh } from 'react-div-100vh'
import { isMobile } from "react-device-detect";
import { badgesInfo } from "../utils/constants";
import {
  BASE_URL,
  fetchDuck,
  getProofs
} from "../utils/api";
import Tooltip from "@material-ui/core/Tooltip";
import { useStateContext } from "../utils/web3";

function Profile(props: any) {
  const { royaltiesClaim, claimed, accessToken } = useStateContext();
  const { collection, user, totalDucks } = props;
  const [loading, setLoading] = useState(false);
  const [badges, setBadges] = useState<any>(badgesInfo);
  const [claimable, setClaimable] = useState<any>(0);
  const [date, setDate] = useState<any>('-');
  const [canClaim, setCanClaim] = useState<any>(false);
  const [profilePic, setProfilePic] = useState<any>("https://ipfs.io/ipfs/QmZtrtckm1AFQVZMatmZArwUrGjNCpY98DXFwevsHb7sUF/1.jpg");
  const height = use100vh()
  const currentHeight = height ? height * 1.2 : '120vh'

  const connectSocial = () => {}

  const getPfp = async () => {
    setProfilePic(await fetchDuck(user.addr,collection))
  }

  const claim = async () => {
    setLoading(true);
    setCanClaim(false);
    const data = await getProofs(accessToken);
    if ( data.address && data.proofs.length > 0 && data.amount) {
      try {
        const response = await royaltiesClaim(data.address,data.amount,data.proofs);
        console.log(response);
        const res = await claimed(user.addr, data.hash_version);
        if (res) {
          setClaimable(0);
          setDate("-");
          setCanClaim(false);
        }
      } catch(e) {
        console.log(e);
        try {
          const res = await claimed(user.addr, data.hash_version);
          if (res) {
            setClaimable(0);
            setDate("-");
            setCanClaim(false);
          }
        } catch {
          setCanClaim(true);
        }
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (user) {
      setBadges(user.badges);
      getPfp();
    }
  }, [user, collection]); //eslint-disable-line

  const verify =async (claimable:any, valid:any, canClaim:any) => {
    try {
      const data = await getProofs(accessToken);
      console.log(data)
      const res = await claimed(user.addr, data.hash_version);
      console.log(res)
      if(!res) {
        setClaimable(claimable);
        setDate(valid);
        setCanClaim(canClaim);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  }

  useEffect(() => {
    if (user && user.reward) {
      setLoading(true)
      verify(user.reward.amount,user.reward.valid, user.reward.canClaim);
    }
  }, [user, collection]); //eslint-disable-line

  return (
    <div style={{ height: isMobile ? currentHeight : '100%' }} className={`relative flex flex-col md:flex-row items-center justify-between ${collection}-bg h-full w-full rounded-3xl content`}>
      <div className="relative flex flex-col items-center justify-between rounded-3xl og-bg-opacity-black h-full w-full md:w-5/12 pb-5 px-5 pt-10">
        {
          profilePic ? (
            <ProdilePFP image={profilePic}/>
          ) : 
          (
            <ProdilePFP image={"https://ipfs.io/ipfs/QmZtrtckm1AFQVZMatmZArwUrGjNCpY98DXFwevsHb7sUF/1.jpg"}/>
          )
        } 
        <div className="uppercase font-bold txt-badge text-7xl md:text-5xl">
          BADGES:
        </div>
        <Badges badges={badges} collection={collection}></Badges>
      </div>
      <div className="flex flex-col items-center h-full justify-around w-full md:w-7/12 p-2 md:p-10">
        <a href="https://nonconformistducks.com/banners"
          target="_blank"
          className="none md:flex rounded-lg h-3/6"
          rel="noopener noreferrer">
          <img
              src={Banner}
              className="rounded-lg h-full"
              alt="Banner" />
        </a>
        {user && user.twitter &&
          <div  className="flex flex-col md:flex-row items-center w-full justify-around h-3/6 md:h-1/6">
            <button className="flex items-center og-bg-black rounded-lg m-auto h-2/5 md:h-3/5 px-2 py-1 social">
              <img src={TwitterIcon} alt={"Twitter"} className="h-3/5 md:h-4/5 mr-2"/>
              <div className="uppercase txt-white text-4xl md:text-2xl">
                {user.twitter}
              </div>
            </button>
          </div>
        }

          {(!user || !user.twitter) &&
              <div  className="flex flex-col md:flex-row items-center w-full justify-around h-3/6 md:h-1/6">
                <a
                 href={`${BASE_URL}login/twitter`}
                 rel="noopener noreferrer"
                >
                  <ConnectSocial text={"Twitter"} imageSrc={TwitterIcon} action={connectSocial}></ConnectSocial>
                </a>
              </div>
          }
        <div className="flex flex-col md:flex-row items-center md:items-end md:justify-around w-full h-4/6 md:h-2/6">
          <div data-tip="hello world" className="flex flex-col items-center justify-around h-full w-5/6 md:w-4/6">
            <Tooltip title={
                  <React.Fragment>
                    <div className="leading-normal txt-white text-2xl">By pressing the button, you will claim the rewards of all the collections that you have successfully fulfilled with the proposed badges</div>
                  </React.Fragment>
                }
               placement="right-start">
              <button  onClick={canClaim ? claim : ()=>{}} className="flex og-bg-black disabled:opacity-70 rounded-lg h-5/6 md:h-4/6 w-4/5">
                <div className="m-auto leading-normal uppercase txt-white text-5xl md:text-2xl">
                  { loading && <div className="loader">Loading...</div> }
                  { !loading &&
                    <>
                      {'claim all'}
                      <br></br>
                      { claimable + ' eth'}
                    </>
                  }
                </div>
              </button>
            </Tooltip>
            <div className="uppercase font-bold text-white text-6xl md:text-2xl">
              {'Valid until '+ date}
            </div>
          </div>
          <TotalDucks collection={collection} amount={totalDucks}></TotalDucks>
        </div>
      </div>
    </div>
  )
}

export default Profile;