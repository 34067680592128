import { useState, useEffect } from 'react';
import constate from 'constate';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
// @ts-ignore
import WalletConnectProvider from '@walletconnect/web3-provider';
import AppContract from "../abis/NonconformistDucks.json";
import AppScorchingContract from "../abis/ScorchingDucks.json";
import NonconformistDucks2Gen from "../abis/NonconformistDucks2Gen.json";
import NonconformistRoyalties from "../abis/Duckwards.json";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

import {
  COLLECTIONS,
  OG_CONTRACT_ADDRESS,
  SCORCHING_CONTRACT_ADDRESS,
  SECOND_CONTRACT_ADDRESS,
  ROYALTIES_CONTRACT_ADDRESS
} from "../utils/constants";

import { getSignature, loginWallet } from './api';

const useCustomState = () => {

  let _token = localStorage.getItem('fcm-token');

  const requiredNetworkId = 1;

  // Web3Modal
  const providerOptions = {
    walletlink: {
      package: CoinbaseWalletSDK, 
      options: {
        appName: "Web 3 Modal Demo",
        infuraId: "https://mainnet.infura.io/v3/25437149b33e4a2185451746da28f9dd" 
      }
    },
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "https://mainnet.infura.io/v3/25437149b33e4a2185451746da28f9dd",
      },
    },
  };
  const web3Modal = new Web3Modal({
    network: 'mainnet',
    cacheProvider: true,
    providerOptions,
  });

  // State
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [account, setAccount] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');

  const [network, setNetwork] = useState<number>(0);
  // @ts-ignore
  const [, setProvider] = useState<any>(null);
  const [ogContract, setOgContract] = useState<any>(null);
  const [royaltiesContract, setRoyaltiesContract] = useState<any>(null);
  const [hellContract, setHellContract] = useState<any>(null);
  const [secondContract, setSecondContract] = useState<any>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [token, setToken] = useState<string>(_token ? _token : '');

  // Effects
  useEffect(() => {
    if ( web3 ) {
      const ogContractInstance = new web3.eth.Contract(
        // @ts-ignore
        AppContract.abi,
        OG_CONTRACT_ADDRESS
      );
  
      const scorchingContractInstance = new web3.eth.Contract(
        // @ts-ignore
        AppScorchingContract.abi,
        SCORCHING_CONTRACT_ADDRESS
      );

      const secondContractInstance = new web3.eth.Contract(
        // @ts-ignore
        NonconformistDucks2Gen.abi,
        SECOND_CONTRACT_ADDRESS
      );

      const royaltiesContractInstance = new web3.eth.Contract(
        // @ts-ignore
        NonconformistRoyalties.abi,
        ROYALTIES_CONTRACT_ADDRESS
      );
  
  
      setOgContract(ogContractInstance);
      setHellContract(scorchingContractInstance);
      setSecondContract(secondContractInstance);
      setRoyaltiesContract(royaltiesContractInstance);
    }
  }, [web3]); //eslint-disable-line

  useEffect(() => {
    const intervalNetwork = setInterval(function () {
      if (web3) {
        web3.eth.net.getId((err, currentNet) => {
          // No error and change in network
          if (!err && network > 0 && currentNet !== network) {
            if (currentNet === requiredNetworkId) {
              window.location.reload();
            } else if (network === requiredNetworkId) {
              //showNetworkErrorMessage();
            }
            setNetwork(currentNet);
          }
        });
      }
    }, 1000);

    return () => {
      clearInterval(intervalNetwork);
    };
  }, [network]); //eslint-disable-line

  const login = async (_provider:any, _web3:any) => {
    let _account = '';
    if (_provider && _provider.selectedAddress) {
      _account = _provider.selectedAddress;
      
    }
    if (!_account && _provider && _provider.accounts) {
      _account = _provider.accounts[0];
    }
    if (!account && _provider && _provider.address) {
      _account = _provider.address;
    }
    const message = await getSignature();
    await _web3.eth.personal.sign(message, _account,'',   async (err:any,res:any) => {
      if (res) {
        const _accessToken = await loginWallet(res, _account);
        if (_accessToken) {
          setAccessToken(_accessToken)
        }
        setAccount(_account);
        setIsConnected(true);
      }
    });
  }

  const royaltiesClaim = async (address:any,amount:any,proofs:any) => {
    return await royaltiesContract.methods.claim(address,amount+ "",proofs).send({
      from: address,
      gas: 123414
    });
  }

  const claimed =async (address:any,version:any) => {
    console.log(web3)
    console.log(await royaltiesContract.methods.claimed(version, address).call())
    return await royaltiesContract.methods.claimed(version, address).call();
  }

  const connectWallet = async () => {
    try {
      const _provider = await web3Modal.connect();
      const _web3: Web3 = new Web3(_provider);
      setWeb3(_web3);
      setProvider(_provider);
      login(_provider, _web3);
      // TODO - review why _web3.eth.getAccounts() is not working
      let netId = await _web3.eth.net.getId();
      setNetwork(netId);
      //if (requiredNetworkId !== netId) showNetworkErrorMessage();
    } catch (e) {
      console.log('Error > Connecting wallet');
      console.log(e);
    }
  };

  const disconnectWallet = () => {
    try {
      // @ts-ignore
      web3?.currentProvider.close();
    } catch (e) {}
    web3Modal.clearCachedProvider();
    setWeb3(null);
    setIsConnected(false);
    setAccount('');
  };

  const balance = async (collection:string) => {
    switch ( collection ) {
      case COLLECTIONS.OG:
        return await ogContract.methods.balanceOf(account).call();
      case COLLECTIONS.HELL:
        return await hellContract.methods.balanceOf(account).call();
      case COLLECTIONS.SECOND:
        return await secondContract.methods.balanceOf(account).call();
   }
  }

  // FCM notifications
  const saveToken = (_token: string) => {
    if (_token !== '') {
      setToken(_token);
      localStorage.setItem('fcm-token', _token);
    }
  };

  return {
    web3,
    connectWallet,
    disconnectWallet,
    royaltiesClaim,
    claimed,
    balance,
    isConnected,
    account,
    token,
    saveToken,
    accessToken
  };
};

const [StateProvider, useStateContext] = constate(useCustomState);

export { StateProvider, useStateContext };
